<template>
  <v-card flat dark class="pb-3 transparent">
    <v-list class="text-uppercase" :aria-busy="tweening">
      <v-list-item class="py-0">
        {{ $t("balanceElement.balanceAvailable") }}:
      </v-list-item>
      <v-list-item><BaseMoney class="text-h5" :amount="tweenedBalance.balance"/></v-list-item>
      <v-list-item class="mt-5">
        <v-btn to="/transferfunds/deposit" height="3rem" tile outlined>
          {{ $t("balanceElement.transferFunds") }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>
import TWEEN from "@tweenjs/tween.js"

import Vue from 'vue'

export default Vue.extend({
  name: "Balance",
  props: {
    immediate: { type: Boolean, default: true },
  },
  data() {
    return {
      tweenedBalance: { balance: 0 },
      changed: false,
    }
  },
  computed: {
    user: function() {
      return this.$auth.user()
    },
    balance: function() {
      const currentAccount = this.$store.state.account
      if (currentAccount && currentAccount.balance) {
        return currentAccount.balance
      }
      return 0
    },
    tweening() {
      return this.balance != this.tweenedBalance.balance
    },
  },
  watch: {
    currentInvestAccount: function() {
      this.activeBankAccount = null
      this.getCurrentBankAccount()
    },
    balance: {
      immediate: true,
      handler() {
        if (!this.immediate && !this.changed) {
          this.tweenedBalance.balance = this.balance
          this.changed = true
          return
        }

        const animate = () => {
          if (TWEEN.update()) {
            requestAnimationFrame(animate)
          } else {
            this.tweenedBalance.balance = this.balance // avoid floats, just in case
          }
        }
        new TWEEN.Tween(this.tweenedBalance)
          .to({ balance: this.balance }, 750)
          .easing(TWEEN.Easing.Quadratic.Out)
          .start()

        animate()
      },
    },
  },
})
</script>
