<template>
  <v-navigation-drawer
    v-if="$auth.user() && $route.name && $route.name != 'Onboarding' && $route.name != 'ResetPasswordConfirm'"
    :key="drawerRefreshKey"
    v-model="drawer"
    app dark
    color="#1d1f24"
    width="23rem"
    clipped
    mobile-breakpoint="sm"
    class="d-print-none"
    :temporary="!$vuetify.breakpoint.mdAndUp"
    :expand-on-hover="mini && $vuetify.breakpoint.mdAndUp && expandOnHover"
    :mini-variant.sync="isMiniVariant"
    @transitionend="transitionEnd"
  >
    <v-btn
      fab
      right
      absolute
      class="mt-7 mt-md-15 mr-sm-n11"
      @click="drawerToggle()"
    >
      <v-icon v-if="mini && $vuetify.breakpoint.mdAndUp">mdi-chevron-right</v-icon>
      <v-icon v-else>mdi-chevron-left</v-icon>
    </v-btn>
    <v-card
      flat
      min-width="500"
      class="mx-8 my-4 transparent"
      :style="`visibility: ${isMiniVariant ? 'hidden' : 'visible'}`"
    >
      <Balance :immediate="drawerRefreshKey == 0"/>
    </v-card>

    <v-divider/>

    <v-list class="pb-16">
      <template v-for="item in items">
        <v-divider v-if="typeof item == 'string'" :key="item" class="my-1"/>
        <template v-else>
          <v-list-item
            v-if="!item.items"
            :key="item.title"
            link
            class="py-2"
            :to="item.url"
          >
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content class="font-weight-bold">
              <v-list-item-title v-text="$t(item.title)"/>
            </v-list-item-content>
          </v-list-item>

          <v-list-group
            v-else
            :key="item.title"
            v-model="item.active"
            active-class="monestro-halfactive"
            :prepend-icon="item.icon"
            no-action
            :group="item.group"
            eager
          >
            <template #activator>
              <v-list-item-content class="font-weight-bold">
                <v-list-item-title class="py-3" v-text="$t(item.title)"/>
              </v-list-item-content>
            </template>

            <v-list-item
              v-for="child in item.items"
              :key="child.title"
              link
              class="ml-1 pl-9"
              active-class="monestro-active"
              :to="child.url"
              :disabled="child.disabled"
            >
              <v-list-item-icon>
                <v-icon :disabled="child.disabled">{{ child.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content class="font-weight-regular">
                <v-list-item-title v-text="$t(child.title)"/>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </template>
      </template>

      <v-divider/>
      <v-list-item link @click="logout()">
        <v-list-item-icon>
          <v-icon>mdi-logout-variant</v-icon>
        </v-list-item-icon>

        <v-list-item-content class="font-weight-bold">
          <v-list-item-title v-text="$t('navigationDrawer.logout')"/>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import Balance from "../../modules/account/components/Balance"

import Vue from 'vue'

export default Vue.extend({
  name: "BaseNavigationDrawer",
  components: {
    Balance,
  },
  data: () => ({
    mini: false,
    drawer: null, // null means closed on mobile and as open on desktop on initialization
    isMiniVariant: false,
    expandOnHover: true,
    drawerRefreshKey: 0,
  }),
  computed: {
    company() {
      return (this.$store.state.account
           && this.$store.state.account.company) || null
    },
    items() {
      const investorAccountData = this.$store.state.account.company ?
        [{ title: 'navigationDrawer.investorAccountInfo',  url: '/account/personal',          icon: 'mdi-office-building' }] :
        [{ title: 'navigationDrawer.personalInfo',         url: '/account/personal',          icon: 'mdi-card-account-details-outline'} ]

      return [
        { title: 'navigationDrawer.dashboard',             url: '/',                          icon: 'mdi-view-dashboard' },
        { title: 'navigationDrawer.loanMarket',            group: 'loans',                    icon: 'mdi-briefcase-upload',
          items: [
            { title: 'navigationDrawer.primaryMarket',     url: '/loans/primary',             icon: 'mdi-handshake' },
            { title: 'navigationDrawer.secondaryMarket',   url: '/loans/secondary',           icon: 'mdi-account-convert', disabled: !this.$auth.user()?.is_beta_tester },
          ]},
        { title: 'navigationDrawer.autoInvest',            url: '/autoinvest',                icon: 'mdi-flash' },
        { title: 'navigationDrawer.myAccount',             group: 'account',                  icon: 'mdi-account-circle-outline',
          items: [
            ...investorAccountData,
            { title: 'navigationDrawer.myInvestments',     url: '/account/investments',       icon: 'mdi-finance' },
            { title: 'navigationDrawer.accountStatements', url: '/account/statements',        icon: 'mdi-file-multiple' },
            { title: 'navigationDrawer.documents',         url: '/account/documents',         icon: 'mdi-file-document' },
            { title: 'navigationDrawer.settings',          url: '/account/settings',          icon: 'mdi-cog' },
          ],
        },
        { title: 'navigationDrawer.referFriend',           url: '/referrals',                 icon: 'mdi-account-multiple-plus' },
        { title: 'navigationDrawer.transferFunds',         group: 'transferfunds',            icon: 'mdi-bank-transfer',
          items: [
            { title: 'navigationDrawer.depositFunds',      url: '/transferfunds/deposit',     icon: 'mdi-cash' },
            { title: 'navigationDrawer.withdrawFunds',     url: '/transferfunds/withdrawal',  icon: 'mdi-cash-refund' },
          ],
        },
      ].filter(item => !item.beta || this.$auth.user() && this.$auth.user().is_beta_tester)
    },
  },
  methods: {
    logout() {
      this.$auth.logout()
      this.$store.commit("setCurrentInvestmentAccount", {})
    },
    transitionEnd() {
      if (!this.expandOnHover) {
        this.expandOnHover = true
        // XXX Yes, this is nuts. But the navigation drawer is buggy,
        // its internal state gets totally broken when toggling the mini
        // variant repeatedly. Therefore, we bump its key so that Vue
        // recreates the drawer from scratch.
        this.drawerRefreshKey += 1
      }
    },
    drawerToggle() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.expandOnHover = false
        this.mini = !this.mini
        this.isMiniVariant = this.mini
      } else {
        this.drawer = !this.drawer
      }
    },
  },
})
</script>

<style>
/* TODO white color shouldn't be totally white */
.monestro-halfactive {
  color: white !important;
}

.monestro-active .v-list-item__content {
  text-decoration: underline;
}

.monestro-active {
  color: white !important;
}

.monestro-active::before {
  background-color: transparent !important;
}

.v-navigation-drawer > *, .v-list-item {
  letter-spacing: 0.1rem;
}

.v-navigation-drawer .v-list-group__items {
  background: #0f1015;
}

.v-navigation-drawer {
  overflow: visible !important;
}

.v-navigation-drawer__content::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.v-navigation-drawer__content {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.v-navigation-drawer .v-list-group .v-list-group__header__prepend-icon {
  padding: 8px 0;
}
</style>
